import React, { useEffect, useState } from 'react'
import { BrowserRouter as Router, Routes, Route, Navigate, useNavigate, useLocation, useSearchParams } from 'react-router-dom'

import { useAppSelector, useAppDispatch } from './app/hooks'
import { selectAuth, setAuth, cleanAuth, AuthState, AuthStateUser, AuthStatusEnum } from './app/slices/authSlices'
import { getLanguageFileAsync } from './app/slices/i18nSlices'
import { setBrands, cleanBrands } from './app/slices/brandSlices'
import { selectBase, cleanMessage, BaseState } from './app/slices/baseSlices'
import globalConstants from './constants/globalConfig'

// Base component
import { Loading } from './components/Loading'
import { Message } from './components/Message'

// Page component
const LoginPage = React.lazy(() => import('./pages/Login'))
const TwoFAPage = React.lazy(() => import('./pages/TwoFALogin'))
const ValidateEmployeeIdPage = React.lazy(() => import('./pages/ValidateEmployeeId'))
const ValidateEmployeeEmailPage = React.lazy(() => import('./pages/ValidateEmployeeEmail'))
const SecretQuestion = React.lazy(() => import('./pages/SecretQuestion'))
const RegisterPage = React.lazy(() => import('./pages/Register'))
const PasswordRestPage = React.lazy(() => import('./pages/PasswordRest'))
const PasswordRestByTokenPage = React.lazy(() => import('./pages/PasswordRestByToken'))
const PasswordSetPage = React.lazy(() => import('./pages/passwordSet'))
const SelectLanguagePage = React.lazy(() => import('./pages/SelectLanguage'))
const SelectStorePage = React.lazy(() => import('./pages/SelectStore'))
const SelectBrandPage = React.lazy(() => import('./pages/SelectBrands'))
const HomePage = React.lazy(() => import('./pages/Home'))
const CustomerSearchPage = React.lazy(() => import('./pages/CustomerSearch'))
const CustomerProfilePage = React.lazy(() => import('./pages/CustomerProfile'))
const NewCustomerListPage = React.lazy(() => import('./pages/NewCustomerList'))
const NewCustomerProfilePage = React.lazy(() => import('./pages/NewCustomerProfile'))
const TransactionPage = React.lazy(() => import('./pages/Transaction'))
const TransactionsPage = React.lazy(() => import('./pages/Transactions'))
const ProductSearchPage = React.lazy(() => import('./pages/ProductSearch'))
const MyProfilePage = React.lazy(() => import('./pages/MyProfile'))
const SupportPage = React.lazy(() => import('./pages/Support'))
const CampaignsPage = React.lazy(() => import('./pages/Campaigns'))
const ContactListPage = React.lazy(() => import('./pages/ContactList'))
const HermesCustomerPage = React.lazy(() => import('./pages/MultiBrandConsent'))

export default function App() {
	const base: BaseState = useAppSelector(selectBase)
	const dispatch = useAppDispatch()

	const closeMessage = () => {
		dispatch(cleanMessage())
	}

	return (
		<Router>
			<CustomRoutes />
			<Message visible={base.message?.visible ? true : false} rootId={'message-root'} closeMessage={closeMessage}>
				{base.message?.content}
			</Message>
			<Loading loadQueues={base.loadingQueue ? base.loadingQueue : []} />
		</Router>
	)
}

// Extract the Routes component separately for route change listening
const CustomRoutes = () => {
	const navigate = useNavigate()
	const location = useLocation()
	const dispatch = useAppDispatch()
	const auth: AuthState = useAppSelector(selectAuth)
	const [searchParams,] = useSearchParams();
	const token = searchParams.get('token');
	const accessToken = localStorage.getItem('AccessToken') ? localStorage.getItem('AccessToken') : token ? token : null
	const accessKey = localStorage.getItem('AccessKey') ? localStorage.getItem('AccessKey') : token ? token : null
	const authorization = localStorage.getItem('Authorization') ? localStorage.getItem('Authorization') : token ? token : null
	const info = localStorage.getItem('info')
	const user = localStorage.getItem('user')
	const brands = localStorage.getItem('brands')
	const [i18nPageFlag, setI18nPageFlag] = useState<boolean>(false)
	const [i18nCommonFlag, setI18nCommonFlag] = useState<boolean>(false)

	// Listen auth state
	useEffect(() => {
		// 401/403 lose token
		if ((!accessToken || !accessKey || !authorization) && auth.authStatus === AuthStatusEnum.Logined) {
			dispatch(cleanAuth())
			dispatch(cleanBrands())
			navigate(globalConstants.ROUTES.LOGIN)
		}

		// Refresh browser
		if (accessToken && accessKey && authorization && auth.authStatus === AuthStatusEnum.None) {
			if (token) {
				localStorage.setItem('AccessToken', token)
				localStorage.setItem('AccessKey', token)
				localStorage.setItem('Authorization', token)
			}
			dispatch(
				setAuth({
					accessToken: accessToken,
					accessKey: accessKey,
					authorization: authorization,
					authStatus: AuthStatusEnum.Logined,
					authVisible: true,
					user: user ? JSON.parse(user) : token ? {
						id: searchParams.get('emp_id'),
						country: searchParams.get('country'),
						store_id: searchParams.get('store_id')
					} : null,
					info: info ? JSON.parse(info) : null
				})
			)

			if (brands) {
				dispatch(setBrands(JSON.parse(brands)));
			} else {
				localStorage.setItem('QR-Brands', JSON.parse(decodeURIComponent(String(searchParams.get('brand')))))
			}
		}
	}, [auth, dispatch, navigate, accessToken, accessKey, authorization, user, info, brands, token, searchParams])

	// Load base language data
	useEffect(() => {
		setI18nCommonFlag(false)
		const _getLanguageFile = async () => {
			const localUser = user ? JSON.parse(user) as AuthStateUser : null;
			await dispatch(getLanguageFileAsync({ page: 'errors', language: (localUser && localUser.country ? localUser.country : searchParams.get('country') ? String(searchParams.get('country')) : globalConstants.DEFAULT_LANGUAGE).toLowerCase() })).unwrap()
			await dispatch(getLanguageFileAsync({ page: 'menu', language: (localUser && localUser.country ? localUser.country : searchParams.get('country') ? String(searchParams.get('country')) : globalConstants.DEFAULT_LANGUAGE).toLowerCase() })).unwrap()
			setI18nCommonFlag(true)
		}
		_getLanguageFile()
	}, [dispatch, auth, user, searchParams])
	// Load language data dynamically
	useEffect(() => {
		setI18nPageFlag(false)
		const _getLanguageFile = async () => {
			const localUser = user ? JSON.parse(user) as AuthStateUser : null;
			await dispatch(getLanguageFileAsync({ page: location.pathname.replace('/', ''), language: (localUser && localUser.country ? localUser.country : searchParams.get('country') ? String(searchParams.get('country')) : globalConstants.DEFAULT_LANGUAGE).toLowerCase() })).unwrap()
			setI18nPageFlag(true)
		}
		_getLanguageFile()
	}, [location, dispatch, auth, user, searchParams])

	if (!i18nPageFlag || !i18nCommonFlag) {
		return null
	}

	return (
		<React.Suspense>
			<Routes>
				<Route index element={<Navigate to={globalConstants.ROUTES.SELECT_LANGUAGE} />} />
				<Route path={globalConstants.ROUTES.LOGIN} element={<LoginPage />} />
				<Route path={globalConstants.ROUTES.TWO_FA_LOGIN} element={<TwoFAPage />} />
				<Route path={globalConstants.ROUTES.REGISTER} element={<RegisterPage />} />
				<Route path={globalConstants.ROUTES.VALIDATE_EMPLOYEE_ID} element={<ValidateEmployeeIdPage />} />
				<Route path={globalConstants.ROUTES.VALIDATE_EMPLOYEE_EMAIL} element={<ValidateEmployeeEmailPage />} />
				<Route path={globalConstants.ROUTES.SECRET_QUESTION} element={<SecretQuestion />} />
				<Route path={globalConstants.ROUTES.PASSWORD_REST} element={<PasswordRestPage />} />
				<Route path={globalConstants.ROUTES.PASSWORD_REST_BY_TOKEN} element={<PasswordRestByTokenPage />} />
				<Route path={globalConstants.ROUTES.PASSWORD_SET} element={<PasswordSetPage />} />
				<Route path={globalConstants.ROUTES.SELECT_LANGUAGE} element={<SelectLanguagePage />} />
				<Route path={globalConstants.ROUTES.SELECT_STORE} element={<RequireAuth><SelectStorePage /></RequireAuth>} />
				<Route path={globalConstants.ROUTES.SELECT_BRANDS} element={<RequireAuth><SelectBrandPage /></RequireAuth>} />
				<Route path={globalConstants.ROUTES.HOME} element={<RequireAuth><HomePage /></RequireAuth>} />
				<Route path={globalConstants.ROUTES.CUSTOMER_SEARCH} element={<RequireAuth><CustomerSearchPage /></RequireAuth>} />
				<Route path={globalConstants.ROUTES.CUSTOMER_PROFILE} element={<RequireAuth><CustomerProfilePage /></RequireAuth>} />
				<Route path={globalConstants.ROUTES.NEW_CUSTOMER_PROFILE} element={<RequireAuth><NewCustomerProfilePage /></RequireAuth>} />
				<Route path={globalConstants.ROUTES.NEW_CUSTOMER_LIST} element={<RequireAuth><NewCustomerListPage /></RequireAuth>} />
				<Route path={globalConstants.ROUTES.TRANSACTION} element={<RequireAuth><TransactionPage /></RequireAuth>} />
				<Route path={globalConstants.ROUTES.TRANSACTIONS} element={<RequireAuth><TransactionsPage /></RequireAuth>} />
				<Route path={globalConstants.ROUTES.PRODUCT_SEARCH} element={<ProductSearchPage />} />
				<Route path={globalConstants.ROUTES.SUPPORT} element={<SupportPage />} />
				<Route path={globalConstants.ROUTES.PRODUCT_SEARCH} element={<RequireAuth><ProductSearchPage /></RequireAuth>} />
				<Route path={globalConstants.ROUTES.CAMPAIGNS} element={<RequireAuth><CampaignsPage /></RequireAuth>} />
				<Route path={globalConstants.ROUTES.CONTACT_LIST} element={<RequireAuth><ContactListPage /></RequireAuth>}></Route>
				<Route path={globalConstants.ROUTES.MULTI_BRAND_CONSENT} element={<RequireAuth><HermesCustomerPage /></RequireAuth>} />
				<Route path={globalConstants.ROUTES.MY_PROFILE} element={<RequireAuth><MyProfilePage /></RequireAuth>} />
			</Routes>
		</React.Suspense>
	)
}

const RequireAuth = ({ children }: { children: JSX.Element }) => {
	// Auth handle
	const auth: AuthState = useAppSelector(selectAuth)
	const location = useLocation()
	const [searchParams,] = useSearchParams();
	const token = searchParams.get('token');
	const accessToken = localStorage.getItem('AccessToken') ? localStorage.getItem('AccessToken') : token ? token : null
	const accessKey = localStorage.getItem('AccessKey') ? localStorage.getItem('AccessKey') : token ? token : null
	const authorization = localStorage.getItem('Authorization') ? localStorage.getItem('Authorization') : token ? token : null

	// Navigate handle
	if (!accessToken || !accessKey || !authorization) {
		if (!auth.authVisible && !(auth.authStatus === AuthStatusEnum.Logined)) {
			// Redirect them to the /login page, but save the current location they were
			// trying to go to when they were redirected. This allows us to send them
			// along to that page after they login, which is a nicer user experience
			// than dropping them off on the home page.
			return <Navigate to={globalConstants.ROUTES.LOGIN} state={{ from: location }} replace />
		}
	}

	return children
}
