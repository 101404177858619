import {createSlice, current, PayloadAction} from '@reduxjs/toolkit'
import type {RootState} from '../store'

// --- Types --
// Data
export interface BaseState {
	message?: BaseMessage
	loadingQueue?: BaseLoadQueue[]
}

export interface BaseMessage {
	content?: string
	visible: boolean
	state?: MessageEnum
	showTime?: number
}
export enum MessageEnum {
	NONE,
	ERROR,
	WARN,
	INFO,
	SUCCESS,
}
export interface BaseLoadQueue {
	key: string
	content?: string
}

// --- Functions ---
// Async functions

// Reducer
const initialState: BaseState = {
	message: {
		content: undefined,
		visible: false,
		state: MessageEnum.NONE,
		showTime: undefined,
	},
	loadingQueue: [],
}

export const baseSlice = createSlice({
	name: 'base',
	initialState,
	reducers: {
		setMessage: (state: BaseState, action: PayloadAction<BaseMessage>) => {
			const result = action.payload
			state.message = {
				content: result.content,
				visible: result.visible,
				state: result.state,
			}
		},
		cleanMessage: (state: BaseState) => {
			state.message = {
				content: undefined,
				visible: false,
				showTime: undefined,
				state: MessageEnum.NONE,
			}
		},
		addLoading: (state: BaseState, action: PayloadAction<BaseLoadQueue>) => {
			const curState = current(state)
			let curQueue = curState.loadingQueue ? [...curState.loadingQueue] : []
			curQueue.push(action.payload)
			state.loadingQueue = curQueue
		},
		removeLoading: (state: BaseState, action: PayloadAction<string>) => {
			const curState = current(state)
			state.loadingQueue = curState.loadingQueue?.filter((item: BaseLoadQueue) => {
				return item.key !== action.payload
			})
		},
	},
})

// Sync ation and selector
export const {setMessage, cleanMessage, addLoading, removeLoading} = baseSlice.actions
export const selectBase = (state: RootState) => state.base

export default baseSlice.reducer
