import { ActionReducerMapBuilder, createAsyncThunk, createSlice, PayloadAction, current } from '@reduxjs/toolkit'
import type { RootState } from '../store'

import { getTransaction, Transaction } from '../apis/transactionRequest'
import { ResponseResult } from '../../utils/httpRequest'
import { Product } from '../apis/productRequest'
import { getCampaigns, matchItemCampaign, calculateCampaign, matchCampaign, matchHeaderCampaign, GetCampaignsParams, Campaign } from '../apis/campaignRequest'

// --- Types --
// Data
export interface ChangeProductOfTransactionParams {
	product: Product
	qty: number
}
export interface TransactionState {
	transactionId?: string
	custId?: string | null
	posId?: string | null
	products: Product[]
	currency?: string
	campaign_id?: string
	amount?: number | null
	campaign?: Campaign | null
	headerCampaigns?: Campaign[] | null
}

// --- Functions ---
// Async functions
export const getTransactionAsync = createAsyncThunk('transaction/getTransactionAsync', async (id: string) => {
	const response: ResponseResult<Transaction[]> = await getTransaction(id)
	return response
})
export const getCampaignAsync = createAsyncThunk('transaction/getCampaignAsync', async (params: GetCampaignsParams) => {
	const response: ResponseResult<Campaign[]> = await getCampaigns(params)
	return response
})

// Reducer
const initialState: TransactionState = {
	products: [],
}

export const transactionSlice = createSlice({
	name: 'transaction',
	initialState,
	reducers: {
		replaceProductOfTransaction: (state: TransactionState, action: PayloadAction<Product>) => {
			const payload: Product = action.payload
			const curState: TransactionState = current(state)
			let cloneProduct = [...curState.products]
			cloneProduct.forEach((product: Product, index: number) => {
				if (product.products_id === payload.products_id) {
					cloneProduct[index] = payload
				}
			})
			state.products = cloneProduct
		},
		changeProductOfTransaction: (state: TransactionState, action: PayloadAction<ChangeProductOfTransactionParams>) => {
			const payload: ChangeProductOfTransactionParams = action.payload
			const curState: TransactionState = current(state)

			// Find if the product exists
			const exists = curState.products.filter((product: Product) => {
				return product.products_id === payload.product.products_id
			})
			if (exists.length) {
				state.products = curState.products.map((product: Product) => {
					let clone = { ...product }
					if (product.products_id === payload.product.products_id) {
						let newQty: number = Number(product.qty)
						if (payload.qty < 0) {
							newQty = newQty === 1 ? 1 : newQty + payload.qty
						} else if (payload.qty === 1) {
							newQty += payload.qty
						} else {
							newQty = payload.qty
						}
						clone.qty = String(newQty)
					}
					return calculateBundleItems(clone, 'existing')
				})
			} else {
				state.products.push({ ...payload.product, qty: payload.qty ? String(payload.qty) : '1' })
			}
			state.currency = payload.product.currency ? payload.product.currency : ''
		},
		batchChangeProductOfTransaction: (state: TransactionState, action: PayloadAction<ChangeProductOfTransactionParams[]>) => {
			// debugger
			const payloads: ChangeProductOfTransactionParams[] = action.payload
			const curState: TransactionState = current(state)
			let cloneProducts = [...curState.products]
			payloads.forEach((payload: ChangeProductOfTransactionParams) => {
				// Find if the product exists
				// debugger
				const exists = cloneProducts.filter((product: Product) => {
					return product.products_id === payload.product.products_id
				})
				if (exists.length) {
					cloneProducts.forEach((product: Product, index: number) => {
						let clone = { ...product }
						if (product.products_id === payload.product.products_id) {
							let newQty: number = Number(product.qty)
							// debugger
							if (payload.qty <= 0) {
								// cloneProducts = []
								cloneProducts.splice(index, 1)
							} else {
								newQty = payload.qty
								clone.qty = String(newQty)
								cloneProducts[index] = calculateBundleItems(clone, 'existing');
							}
						}
					})
				} else if (payload.qty > 0) {
					cloneProducts.push({ ...payload.product, qty: payload.qty ? String(payload.qty) : '1' })
				}
			})
			state.products = [...cloneProducts]
			if (cloneProducts.length) {
				state.currency = cloneProducts[0].currency ? cloneProducts[0].currency : ''
			}
		},
		deleteProductOfTransaction: (state: TransactionState, action: PayloadAction<Product>) => {
			const curState: TransactionState = current(state)
			state.products = curState.products.map((product: Product) => {
				if (product.products_id !== action.payload.products_id) {
					return product;
				} else {
					let clone = { ...product };
					clone.qty = '0';
					return clone;
				}
			})
			// state.products = curState.products.filter((product: Product) => {
			// 	return product.products_id !== action.payload.products_id
			// })
		},
		setCampaign: (state: TransactionState, action: PayloadAction<string>) => {
			const curState: TransactionState = current(state)
			state.campaign_id = action.payload
			state.campaign = curState.headerCampaigns ? curState.headerCampaigns.find((campaign: Campaign) => String(campaign.id) === action.payload) : undefined
		},
		clearTransaction: (state: TransactionState) => {
			state.transactionId = '';
			state.custId = null;
			state.posId = null;
			state.products = [];
			state.currency = '';
			state.campaign_id = '';
			state.amount = null;
			state.campaign = null;
			state.headerCampaigns = [];
		}
	},
	extraReducers: (builder: ActionReducerMapBuilder<TransactionState>) => {
		builder
			.addCase(getTransactionAsync.fulfilled, (state: TransactionState, action: PayloadAction<ResponseResult<Transaction[]>>) => {
				const { code, data } = action.payload
				if (code === 200) {
					state.custId = data[0]?.cust_id ? String(data[0]?.cust_id) : null
					state.transactionId = String(data[0]?.id)
					state.products = data[0]?.products
					state.campaign_id = data[0]?.products[0]?.campaign_id ? data[0]?.products[0]?.campaign_id : ''
					state.currency = data[0]?.products[0]?.currency ? data[0]?.products[0].currency : ''
					state.posId = data[0]?.products[0]?.remarks_2 ? data[0]?.products[0]?.remarks_2 : null
				}
			})
			.addCase(getCampaignAsync.fulfilled, (state: TransactionState, action: PayloadAction<ResponseResult<Campaign[]>>) => {
				const { code, data } = action.payload
				if (code === 200) {
					const curState: TransactionState = current(state)
					let products: Product[] = [...curState.products]
					products.forEach((product: Product, index: number) => {
						// Get available item promotions
						const newItemCampaign: Campaign[] = data.filter((campaign: Campaign) => {
							const matched = matchItemCampaign(campaign, product)
							// Get and set the promo that is applied
							if (product.item_campaign_id && product.item_campaign_id === campaign.id) {
								// Add selected promo to product
								// Calculate promotion
								products[index] = calculateCampaign(campaign, product)
							}

							return matched ? matched : false
						})
						products[index] = { ...products[index], campaigns: newItemCampaign }

						// Get available header promotions
						const newHeaderCampaign: Campaign[] = data.filter((campaign: Campaign) => {
							const matched = matchHeaderCampaign(campaign, product)
							if (curState.campaign_id === String(campaign.id)) {
								state.campaign = campaign
							}
							return matched ? matched : false
						})
						if (newHeaderCampaign.length) {
							let thisCampaign: Campaign[] = []
							newHeaderCampaign.forEach((newHeaderCampaign: Campaign) => {
								const exists: Campaign[] | undefined = curState.headerCampaigns?.filter(
									(headerCampaign: Campaign) =>
										newHeaderCampaign.code === headerCampaign.code &&
										newHeaderCampaign.valid_from === headerCampaign.valid_from &&
										newHeaderCampaign.valid_to === headerCampaign.valid_to &&
										newHeaderCampaign.category === headerCampaign.category &&
										newHeaderCampaign.discount === headerCampaign.discount &&
										newHeaderCampaign.percent === headerCampaign.percent &&
										newHeaderCampaign.qty === headerCampaign.qty
								)
								if (!exists || exists.length <= 0) {
									thisCampaign.push(newHeaderCampaign)
								}
							})
							state.headerCampaigns = [...(curState.headerCampaigns ? curState.headerCampaigns : []), ...thisCampaign]
						}
					})
					state.products = products
				}
			})
	},
})

function calculateBundleItems(product: Product, type: 'new' | 'existing'): Product {
	let thisCampaign: Campaign[] | null = matchCampaign(product)
	if (thisCampaign && thisCampaign[0] && thisCampaign[0].type === 'bundle-offer' && product.discountedItems) {
		if (Number(product.qty) > 1 && type === 'new' && product.qty !== product.discountedItems) {
			let newProduct = JSON.parse(JSON.stringify(product))
			newProduct.qty = product.discountedItems
			newProduct.item_discount_price = Number(product.item_price ? product.item_price : null) * (Number(thisCampaign[0].discount ? thisCampaign[0].discount : null) / 100)
			newProduct.item_discount = thisCampaign[0].discount
			newProduct.net_price = null
			newProduct.foc_flag = Number(thisCampaign[0].discount) === 100
			delete newProduct.transaction_id

			product.qty = String(Number(product.qty ? product.qty : null) - Number(product.discountedItems ? product.discountedItems : null))
			product.item_campaign_code = null
			product.item_campaign_id = null
			product.item_discount_price = null
			product.item_discount = null
			product.net_price = null
		}
	} else {
		product.item_discount_price = product.net_price != null ? String(Number(product.item_price ? product.item_price : null) - Number(product.net_price ? product.net_price : null)) : String(Number(product.item_price ? product.item_price : null) * (Number(thisCampaign && thisCampaign[0]?.discount ? thisCampaign[0]?.discount : null) / 100))
		product.item_discount = thisCampaign && thisCampaign[0]?.discount ? thisCampaign[0]?.discount : null
		product.foc_flag = thisCampaign ? Number(thisCampaign[0]?.discount) === 100 : null
	}

	return product
}

// Sync ation and selector
export const { changeProductOfTransaction, replaceProductOfTransaction, batchChangeProductOfTransaction, deleteProductOfTransaction, setCampaign, clearTransaction } = transactionSlice.actions
export const selectTransaction = (state: RootState) => state.trasaction

export default transactionSlice.reducer
