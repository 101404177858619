import { createSlice } from '@reduxjs/toolkit'
import type { RootState } from '../store'

// Reducer
const initialState = {
	isShow: false,
	newProfileShow: false
}

export const popUpSlice = createSlice({
	name: 'popup',
	initialState,
	reducers: {
		showPopUp: (state) => {
			state.isShow = true
		},
		hidePopUp: (state) => {
			state.isShow = false
		},
		showNewProfilePopUp: (state) => {
			state.newProfileShow = true
		},
		hideNewProfilePopUp: (state) => {
			state.newProfileShow = false
		}
	},
})

// Sync ation and selector
export const { showPopUp, hidePopUp, showNewProfilePopUp, hideNewProfilePopUp } = popUpSlice.actions
export const selectPopUp = (state: RootState) => state.popup

export default popUpSlice.reducer
