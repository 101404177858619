import httpRequest, { ResponseResult } from "../../utils/httpRequest";
import { Product } from "./productRequest";

// -- Types --
export interface GetCampaignsParams {
    emp_id: string
    store_id: string
    country: string
}

export interface Campaign {
    id: string
    brand_id: string | null
    brand: string | null
    product_id: string | null
    code: string | null
    percent: string | null
    discount: string | null
    type: string | null
    qty: string | null
    uom: string | null
    category: string | null
    description: string | null
    valid_from: string | null
    valid_to: string | null
}

// -- Functions --
export async function getCampaigns(params: GetCampaignsParams): Promise<ResponseResult<Campaign[]>> {
    const url = `/trans/campaign`;
    return await httpRequest.post<ResponseResult<Campaign[]>>(url, params).then((res: any) => {
        return res;
    }).catch((err: ResponseResult) => err)
}

// -- Other functions --
// Apply promotions to products
export function calculateCampaign(campaign: Campaign, product: Product) {
    product.item_campaign_id = campaign.id ? String(campaign.id) : '';
    product.item_campaign_code = campaign.code ? campaign.code : '';
    product.item_discount = campaign.discount ? campaign.discount : '';

    // Dollar discount
    if (campaign.type === 'price-discount') {
        product.net_price = String(Number(campaign.discount ? campaign.discount : null) > Number(product.item_price ? product.item_price : null) ? 0.00 : Number(product.item_price ? product.item_price : null) - Number(campaign.discount ? campaign.discount : null));
        product.item_discount_price = campaign.discount;
        // Percentage discount
    } else if (campaign.type === 'percentage-discount') {
        product.net_price = String((1 - (Number(campaign.discount ? campaign.discount : null) / 100) * Number(product.item_price ? product.item_price : null)));
        product.item_discount_price = String(Number(campaign.discount ? campaign.discount : null) / 100 * Number(product.item_price ? product.item_price : null));
        // Special price
    } else if (campaign.type === 'special-price') {
        product.net_price = campaign.discount;
        product.item_discount_price = String(Number(product.item_price ? product.item_price : null) - Number(campaign.discount ? campaign.discount : null));
        // Bundle offer
    } else if (campaign.type === 'bundle-offer') {
        product.net_price = product.net_price ? product.net_price : product.item_price;
        product.item_discount_price = product.item_discount_price ? product.item_discount_price : null;
        // Gift with purchase
    } else if (campaign.type === 'free-gift') {
        product.net_price = product.item_price;
        product.item_discount_price = String(0);
    }

    return product;
}
// Apply promotions to cart
export function calculateCartCampaignForTransactions(campaign: Campaign, total: number): string {
    let cal: number = 0.00;
    let discount: number = campaign.discount ? Number(campaign.discount) : 0.00;
    if (campaign.percent === null) {
        cal = discount > total ? 0.00 : total - discount;
    } else if (campaign.percent.toLowerCase() === 'x') {
        cal = total * (1 - (discount / 100));
    } else {
        cal = total;
    }

    return cal.toFixed(2);
}
// Apply promotions to cart
export function calculateCartCampaign(campaign: Campaign, total: number): string {
    let cal: number = 0.00;
    let discount: number = campaign.discount ? Number(campaign.discount) : 0.00;
    if (campaign.type === 'price-discount') {
        cal = discount > total ? 0.00 : total - discount;
    } else if (campaign.type === 'percentage-discount') {
        cal = total * (1 - (discount / 100));
    } else if (campaign.type === 'free-gift') {
        cal = total;
    }

    return cal.toFixed(2);
}
// Get matching promo
export function matchCampaign(product: Product): Campaign[] | null {
    const attachedCampaigns: Campaign[] | undefined | null = product.campaigns;
    const id: string = product.item_campaign_id ? product.item_campaign_id : '';
    let thisCampain: Campaign[] | null = null;
    if (attachedCampaigns) {
        thisCampain = attachedCampaigns.filter((campaign: Campaign) => {
            return campaign.id === id;
        })
    }

    return thisCampain;
}
export function matchItemCampaign(campaign: Campaign, product: Product): Campaign | null {
    let match = null;
    // Reduction in price by a specific amount - brand specific
    // Reduction in price by a specific amount - brand specific & store specific
    if (campaign.brand_id?.toLocaleLowerCase() === product.brand_id?.toLocaleLowerCase() && campaign.product_id === null && campaign.discount !== null && Number(campaign.qty) === 1 && campaign.uom?.toLocaleLowerCase() === 'pc' && campaign.percent === null && campaign.category?.toLocaleLowerCase() === 'i') {
        campaign.type = 'price-discount';
        match = campaign;
        // Reduction in price by a specific amount - item (Promotion for All the stores for specific SKU)
        // Reduction in price by a specific amount - Item specific & store specific
    } else if (campaign.brand_id?.toLocaleLowerCase() === product.brand_id?.toLocaleLowerCase() && campaign.product_id?.toLocaleLowerCase() === product.products_id?.toLocaleLowerCase() && campaign.discount !== null && Number(campaign.qty) === 1 && campaign.uom?.toLocaleLowerCase() === 'pc' && campaign.percent === null && campaign.category?.toLocaleLowerCase() === 'i') {
        campaign.type = 'price-discount';
        match = campaign;
        // Reduction in price by percentage - item (Promotion for all the Stores for specific SKU)
        // Reduction in price by percentage - Item specific & store specific
    } else if (campaign.brand_id?.toLocaleLowerCase() === product.brand_id?.toLocaleLowerCase() && campaign.product_id?.toLocaleLowerCase() === product.products_id?.toLocaleLowerCase() && campaign.discount !== null && Number(campaign.qty) === 1 && campaign.uom?.toLocaleLowerCase() === 'pc' && campaign.percent?.toLocaleLowerCase() === 'x' && campaign.category?.toLocaleLowerCase() === 'i') {
        campaign.type = 'percentage-discount';
        match = campaign;
        // Reduction in price by percentage - store specific (For All Brands and All SKU's in specific store)
    } else if (campaign.brand_id === null && campaign.product_id === null && campaign.discount !== null && Number(campaign.qty) === 1 && campaign.uom?.toLocaleLowerCase() === 'pc' && campaign.percent?.toLocaleLowerCase() === 'x' && campaign.category?.toLocaleLowerCase() === 'i') {
        campaign.type = 'percentage-discount';
        match = campaign;
        // Reduction in price by percentage - Brand specific (Selective Brand for all the stores)
        // Reduction in price by percentage - brand specific & store specific
    } else if (campaign.brand_id?.toLocaleLowerCase() === product.brand_id?.toLocaleLowerCase() && campaign.product_id === null && campaign.discount !== null && Number(campaign.qty) === 1 && campaign.uom?.toLocaleLowerCase() === 'pc' && campaign.percent?.toLocaleLowerCase() === 'x' && campaign.category?.toLocaleLowerCase() === 'i') {
        campaign.type = 'percentage-discount';
        match = campaign;
        // A Special Price on a specific item (For All the stores)
        // A Special Price for a specific item - store specific
    } else if (campaign.brand_id?.toLocaleLowerCase() === product.brand_id?.toLocaleLowerCase() && campaign.product_id?.toLocaleLowerCase() === product.products_id?.toLocaleLowerCase() && campaign.discount !== null && Number(campaign.qty) === 1 && campaign.uom?.toLocaleLowerCase() === 'pc' && campaign.percent === null && campaign.category?.toLocaleLowerCase() === 's') {
        campaign.type = 'special-price';
        match = campaign;
    } else if (campaign.brand_id === null && campaign.product_id?.toLocaleLowerCase() === product.products_id?.toLocaleLowerCase() && campaign.discount !== null && Number(campaign.qty) === 1 && campaign.uom?.toLocaleLowerCase() === 'pc' && campaign.percent === null && campaign.category?.toLocaleLowerCase() === 's') {
        campaign.type = 'special-price';
        match = campaign;
        // A free item if bought in a bundle - by Store & Brand
        // A free item if bought in a bundle - by brand (for All the stores)
    } else if (campaign.brand_id?.toLocaleLowerCase() === product.brand_id?.toLocaleLowerCase() && campaign.product_id === null && campaign.discount !== null && campaign.qty === null && campaign.uom === null && campaign.percent?.toLocaleLowerCase() === 'x' && campaign.category?.toLocaleLowerCase() === 'b') {
        campaign.type = 'bundle-offer';
        match = campaign;
        // Gift With Purchase - item
    } else if (campaign.brand_id?.toLocaleLowerCase() === product.brand_id?.toLocaleLowerCase() && campaign.product_id?.toLocaleLowerCase() === product.products_id?.toLocaleLowerCase() && Number(campaign.discount) === 0 && Number(campaign.qty) === 1 && campaign.uom?.toLocaleLowerCase() === 'pc' && campaign.percent === null && campaign.category?.toLocaleLowerCase() === 'g') {
        campaign.type = 'free-gift';
        match = campaign;
        // Gift With Purchase - by Brand (For All the stores)
        // Gift With Purchase - by store & brand specific 
    } else if (campaign.brand_id?.toLocaleLowerCase() === product.brand_id?.toLocaleLowerCase() && campaign.product_id?.toLocaleLowerCase() === product.products_id?.toLocaleLowerCase() && Number(campaign.discount) === 0 && Number(campaign.qty) === 1 && campaign.uom === null && campaign.percent === null && campaign.category?.toLocaleLowerCase() === 'i') {
        campaign.type = 'free-gift';
        match = campaign;
    }
    else if (campaign.brand_id?.toLocaleLowerCase() === product.brand_id?.toLocaleLowerCase() && campaign.product_id === null && Number(campaign.discount) === 0 && campaign.qty === null && campaign.uom === null && campaign.percent === null && campaign.category?.toLocaleLowerCase() === 'g') {
        campaign.type = 'free-gift';
        match = campaign;
    } else {
        match = null;
    }

    // Return options
    /*
          1) null
      2) 'price-discount'
      3) 'percentage-discount'
      4) 'special-price'
      5) 'bundle-offer'
      5) 'free-gift'
    */
    return match;
}
export function matchHeaderCampaign(campaign: Campaign, product: Product): Campaign | null {
    let match = null;
    // Reduction in price by a specific amount - store specific
    if (campaign.brand_id === null && campaign.product_id === null && campaign.discount !== null && campaign.qty === null && campaign.uom === null && campaign.percent === null && campaign.category?.toLocaleLowerCase() === 'h') {
        campaign.type = 'price-discount';
        match = campaign;
        // Reduction in price by a specific amount - basket
    } else if (campaign.brand_id?.toLocaleLowerCase() === product.brand_id?.toLocaleLowerCase() && campaign.product_id === null && campaign.discount !== null && campaign.qty === null && campaign.uom === null && campaign.percent === null && campaign.category?.toLocaleLowerCase() === 'h') {
        campaign.type = 'price-discount';
        match = campaign;
        // Reduction in price by percentage - basket
    } else if (campaign.brand_id?.toLocaleLowerCase() === product.brand_id?.toLocaleLowerCase() && campaign.product_id === null && campaign.discount !== null && campaign.qty === null && campaign.uom === null && campaign.percent?.toLocaleLowerCase() === 'x' && campaign.category?.toLocaleLowerCase() === 'h') {
        campaign.type = 'percentage-discount';
        match = campaign;
        // Reduction in price by percentage - basket - store specific
    }
    else if (campaign.brand_id === null && campaign.product_id === null && campaign.discount !== null && campaign.qty === null && campaign.uom === null && campaign.percent?.toLocaleLowerCase() === 'x' && campaign.category?.toLocaleLowerCase() === 'h') {
        campaign.type = 'percentage-discount';
        match = campaign;
        // Gift With Purchase - by store (For all the Brands in the specified store)
    } else if (campaign.brand_id === null && campaign.product_id === null && Number(campaign.discount) === 0 && campaign.qty === null && campaign.uom === null && campaign.percent === null && campaign.category?.toLocaleLowerCase() === 'g') {
        campaign.type = 'free-gift';
        match = campaign;
        // Git
    } else if (campaign.brand_id?.toLocaleLowerCase() === product.brand_id?.toLocaleLowerCase() && campaign.product_id?.toLocaleLowerCase() === product.products_id?.toLocaleLowerCase() && Number(campaign.discount) === 0 && Number(campaign.qty) === 1 && campaign.uom === null && campaign.percent === null && campaign.category?.toLocaleLowerCase() === 'h') {
        campaign.type = 'free-gift';
        match = campaign;
        // Git
    }
    else {
        match = null;
    }

    // Return options
    /*
        1) null
        2) 'price-discount'
        3) 'percentage-discount'
        4) 'free-gift'
    */
    return match;
}