import httpRequest, { ResponseResult } from '../../utils/httpRequest';
import { Campaign } from './campaignRequest';
import { Product } from './productRequest';

// -- Types --
export interface GetTransactionsParams {
    user_id: string
    type: 'myApi' | 'storeApi'
    store_id: string
    country: string
}
export interface AddTransactionParams {
    cust_id: number | null
    emp_id: string
    country: string
    store_id: string
    total_price: string
    products: AddTransactionProductParams[]
    remarks_2?: string
    reference?: string
    localtourist: string
}
export interface AddTransactionProductParams {
    // cust_id: number | null
    products_id: string
    item_price: number
    price: number
    item_discount_price: number | null
    item_discount: number
    discount: string | null
    qty: number
    item_campaign_id: string | null
    campaign_id: string | null
    item_campaign_code: string | null
    campaign_code: string | null
    store_id: string
    emp_id: string
    country: string
    foc_flag: boolean
}
export interface UpdateTransactionParams {
    id: string
    cust_id: number | null
    emp_id: string
    country: string
    store_id: string
    total_price: string
    products: UpdateTransactionProductParams[]
    remarks_2?: string
    reference?: string
}
export interface UpdateTransactionProductParams {
    id: number | null,
    // cust_id: number | null
    products_id: string
    item_price: number
    item_discount_price: number | null
    item_discount: number
    discount: string | null
    qty: number
    item_campaign_id: string | null
    campaign_id: string | null
    item_campaign_code: string | null
    campaign_code: string | null
    store_id: string
    emp_id: string
    country: string
    foc_flag: boolean
}
export interface DeleteTransactionsParams {
    id: number | null
    products: Array<{
        id: number | null
        qty: string | null
    }>
    total_price: string
}
export interface UpdateTransactionsParams {
    id: string
    status: string | null
}
export interface Transaction {
    id: number | null
    name: string | null
    surname: string | null
    total_price: string | null
    status: boolean | null
    emp_id: string | null
    cust_id: number | null
    reference?: string | null
    campaign: Campaign[],
    products: Product[]
}

// -- Methods --
export async function deleteTransactions(params: DeleteTransactionsParams): Promise<ResponseResult<string>> {
    const url = `/trans/orders/update`;
    return await httpRequest.patch<ResponseResult<string>>(url, params).then((res: any) => {
        return res;
    }).catch((err: ResponseResult) => err)
}
export async function updateTransactions(params: UpdateTransactionsParams): Promise<ResponseResult<string>> {
    const url = `/trans/orders/update/status`;
    return await httpRequest.patch<ResponseResult<string>>(url, params).then((res: any) => {
        return res;
    }).catch((err: ResponseResult) => err)
}
export async function getTransaction(id: string): Promise<ResponseResult<Transaction[]>> {
    const url = `/trans/orders/${id}`;
    return await httpRequest.get<ResponseResult<Transaction>>(url).then((res: any) => {
        return res;
    }).catch((err: ResponseResult) => err)
}
export async function addTransaction(params: AddTransactionParams): Promise<ResponseResult<Transaction>> {
    const url = `/trans/orders/add`;
    return await httpRequest.post<ResponseResult<Transaction>>(url, params).then((res: any) => {
        return res;
    }).catch((err: ResponseResult) => err)

}
export async function updateTransaction(params: UpdateTransactionParams): Promise<ResponseResult<string>> {
    const url = `/trans/orders/update`;
    return await httpRequest.patch<ResponseResult<string>>(url, params).then((res: any) => {
        return res;
    }).catch((err: ResponseResult) => err)
}
export async function getTransactions(params: GetTransactionsParams): Promise<ResponseResult<Transaction[]>> {
    let url: string = ``;
    switch (params.type) {
        case 'myApi':
            url = `/trans/orders/${params.user_id}/${params.country}/daily`;
            break;
        case 'storeApi':
            url = `/trans/orders/store/${params.store_id}/${params.country}/daily`;
            break;
        default:
            break;
    }
    return httpRequest.get<ResponseResult<Transaction[]>>(url).then((res: any) => {
        return res;
    }).catch((err: ResponseResult) => err)
}