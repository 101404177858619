import {configureStore, ThunkAction, Action} from '@reduxjs/toolkit'

import baseReducer from './slices/baseSlices'
import i18nReudcer from './slices/i18nSlices'
import authReducer from './slices/authSlices'
import brandReducer from './slices/brandSlices'
import transaction from './slices/transactionSlices'
import popupReducer from './slices/popUpSlice'

export const store = configureStore({
	reducer: {
		i18n: i18nReudcer,
		base: baseReducer,
		auth: authReducer,
		brand: brandReducer,
		trasaction: transaction,
		popup: popupReducer,
	},
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>
