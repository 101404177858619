import httpRequest, { ResponseResult } from '../../utils/httpRequest';

// -- Types --
// Method parameter
export interface CredentialsParams {
    id: string,
    password: string
}
export interface Validate2FATokenParams {
    token: string,
    mfa_code: string
}
export interface RegisterParams {
    id: string
    email: string
    password: string
    country: string
    answer_1: string
    answer_2: string
    answer_3: string
    token?: string
}
export interface RegisterResponse {
    id: string
}
export interface LoginOutParams {
    auth: string
    token: string
}
export interface SetPasswordParams {
    id: string,
    email: string,
    password: string
}
export interface LoginResponse {
    mfatoken: string | null
    // token: string | null,
    // user?: AuthUser | null
}
export interface Validate2FATokenResponse {
    token: string | null
    user?: AuthUser | null
}
export interface UpdateProfileParams {
    id: string
    name: string
    surname: string
    mobile: string
    email: string
    store_id: string
    country: string
}
export interface GetProfileResponse {
    user: [
        {
            address: string | null
            city: string | null
            country: string
            email: string | null
            happinessIndexRolling12Months: string | null
            happinessIndexThisMonth: string | null
            id: string
            mobile: string | null
            name: string
            product_type: string | null
            store: string
            store_description: string
            store_id: string
            surname: string | null
            title: string | null
        }
    ]
}
export interface SetPasswordResponse {
    country: string
    token: string
}
export interface AuthUser {
    id?: string | null
    role?: number | null
    country?: string | null
    store_id?: string | null
}

// -- Methods --
export async function register(params: RegisterParams): Promise<ResponseResult<RegisterResponse>> {
    const url = `/register/confirm`;
    localStorage.setItem('Authorization', params.token ? params.token : '');
    delete params.token
    return await httpRequest.patch<ResponseResult<RegisterResponse>>(url, params).then((res: any) => {
        return res;
    }).catch((err: ResponseResult) => err
    ).finally(() => {
        localStorage.removeItem('Authorization');
    })
}
export async function loginIn(params: CredentialsParams): Promise<ResponseResult<LoginResponse>> {
    const url = `/login`;
    return await httpRequest.post<ResponseResult<LoginResponse>>(url, params).then((res: any) => {
        return res;
    }).catch((err: ResponseResult) => err)
}
export async function send2FAToken(token: string): Promise<ResponseResult<string>> {
    const url = `/login/sendmfa`;
    localStorage.setItem('Authorization', token);
    return await httpRequest.post<ResponseResult<string>>(url).then((res: any) => {
        return res;
    }).catch((err: ResponseResult) => err).finally(() => {
        localStorage.removeItem('Authorization');
    })
}
export async function validate2FAToken(params: Validate2FATokenParams): Promise<ResponseResult<Validate2FATokenResponse>> {
    const url = `/login/validatemfa`;
    localStorage.setItem('Authorization', params.token);
    return await httpRequest.post<ResponseResult<Validate2FATokenResponse>>(url, params).then((res: any) => {
        return res;
    }).catch((err: ResponseResult) => err).catch((err: ResponseResult) => err).finally(() => {
        localStorage.removeItem('Authorization');
    })
}
export async function loginOut(id: string): Promise<ResponseResult<string>> {
    const url = `/logout`;
    return httpRequest.delete<ResponseResult<String>>(url, {
        data: {
            id: id
        }
    }).then((res: any) => {
        return res;
    }).catch((err: ResponseResult) => err)
}
export async function getProfile(id: string): Promise<ResponseResult<GetProfileResponse>> {
    const url = `/user/${id}/profile`;
    return httpRequest.get<ResponseResult<GetProfileResponse>>(url).then((res: any) => {
        return res;
    }).catch((err: ResponseResult) => err)
}
export async function updateProfile(params: UpdateProfileParams): Promise<ResponseResult<string>> {
    const url = `/user/update`;
    return httpRequest.patch<ResponseResult<String>>(url, params).then((res: any) => {
        return res;
    }).catch((err: ResponseResult) => err)
}
export async function checkEmployeeId(id: string): Promise<ResponseResult<{ status: boolean }>> {
    const url = `/psw`;
    return await httpRequest.post<ResponseResult<{ status: boolean }>>(url, {
        id: id
    }).then((res: any) => {
        return res;
    }).catch((err: ResponseResult) => err)
}
export async function checkEmployeeEmail(email: string): Promise<ResponseResult<{ status: boolean }>> {
    const url = `/pswemail`;
    return await httpRequest.post<ResponseResult<{ status: boolean }>>(url, {
        email: email
    }).then((res: any) => {
        return res;
    }).catch((err: ResponseResult) => err)
}
export async function checkQuestion(empId: string, questionFiled: string, answer: string): Promise<ResponseResult<{ token: boolean }>> {
    const url = `/psw/verify`;
    let params: Record<string, string> = {
        id: empId
    }
    params[questionFiled] = answer;
    return await httpRequest.post<ResponseResult<{ token: boolean }>>(url, params).then((res: any) => {
        return res;
    }).catch((err: ResponseResult) => err)
}
export async function resetPassword(empId: string, password: string, token: string): Promise<ResponseResult<string>> {
    const url = `/psw/update`;
    localStorage.setItem('Authorization', token);
    return await httpRequest.patch<ResponseResult<string>>(url, {
        id: empId,
        password: password
    }).then((res: any) => {
        localStorage.removeItem('Authorization');
        return res;
    }).catch((err: ResponseResult) => err
    ).finally(() => {
        // localStorage.removeItem('Authorization');
    })
}
export async function resetPasswordByToken(password: string, token: string): Promise<ResponseResult<string>> {
    const url = `/psw/updatebytoken`;
    return await httpRequest.patch<ResponseResult<string>>(url, {
        token: token,
        password: password
    }).then((res: any) => {
        return res;
    }).catch((err: ResponseResult) => err
    )
}
export async function resetPasswordTokenVerify(token: string): Promise<ResponseResult<{ status: boolean }>> {
    const url = `/reset_password_token_verify`;
    return await httpRequest.post<ResponseResult<{ status: boolean }>>(url, {
        token: token
    }).then((res: any) => {
        return res;
    }).catch((err: ResponseResult) => err)
}
export async function setPassword(params: SetPasswordParams): Promise<ResponseResult<SetPasswordResponse>> {
    const url = `/ceapi/register`;
    return await httpRequest.post<ResponseResult<SetPasswordResponse>>(url, params).then((res: any) => {
        return res;
    }).catch((err: ResponseResult) => err).finally(() => {
        localStorage.removeItem('Authorization');
    })
}

