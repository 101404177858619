import { ActionReducerMapBuilder, createAsyncThunk, createSlice, PayloadAction, current } from '@reduxjs/toolkit';
import type { RootState } from '../store';

import { loginIn, validate2FAToken, register, loginOut, getProfile, updateProfile, CredentialsParams, Validate2FATokenParams, RegisterParams, UpdateProfileParams, RegisterResponse, LoginResponse, Validate2FATokenResponse, GetProfileResponse } from '../apis/authRequest';
import { ResponseResult } from '../../utils/httpRequest';

// --- Types --
// Data
export interface AuthState {
    accessToken: string | null
    accessKey: string | null
    authorization: string | null
    authStatus: AuthStatusEnum
    authVisible: boolean
    user?: AuthStateUser | null
    info?: AuthInfo | null
}
export interface AuthStateUser {
    id?: string | null
    role?: number | null
    country?: string | null
    store_id?: string | null
}
export interface AuthInfo {
    address?: string | null
    city?: string | null
    country?: string
    email?: string | null
    happinessIndexRolling12Months?: string | null
    happinessIndexThisMonth?: string | null
    id?: string
    mobile?: string | null
    name?: string
    product_type?: string | null
    store?: string
    store_description?: string
    store_id?: string
    surname?: string | null
    title?: string | null
}
export enum AuthStatusEnum {
    None,
    Loading,
    LoginError,
    Logined,
}

// --- Functions ---
// Async functions
export const registerAsync = createAsyncThunk('auth/registerAsync', async (params: RegisterParams) => {
    const response: ResponseResult<RegisterResponse> = await register(params);
    return response;
})
export const loginAsync = createAsyncThunk('auth/loginAsync', async (params: CredentialsParams) => {
    const response: ResponseResult<LoginResponse> = await loginIn(params);
    return response;
})
export const validate2FATokenAsync = createAsyncThunk('auth/validate2FATokenAsync', async (params: Validate2FATokenParams) => {
    const response: ResponseResult<Validate2FATokenResponse> = await validate2FAToken(params);
    return response;
})
export const getProfileAsync = createAsyncThunk('auth/getProfileAsync', async (id: string) => {
    const response: ResponseResult<GetProfileResponse> = await getProfile(id);
    return response;
})
export const updateProfileAsync = createAsyncThunk('auth/updateProfileAsync', async (params: UpdateProfileParams) => {
    const response: ResponseResult<string> = await updateProfile(params);
    const result: ResponseResult<Record<string, string | UpdateProfileParams>> = {
        code: response.code,
        msg: response.msg,
        data: {
            backMsg: response.data,
            info: params
        }
    }
    return result
})
export const logoutAsync = createAsyncThunk('auth/logoutAsync', async (id: string) => {
    const response: ResponseResult<string> = await loginOut(id);
    return response;
})

// Reducer
const initialState: AuthState = {
    accessToken: null,
    accessKey: null,
    authorization: null,
    authStatus: AuthStatusEnum.None,
    authVisible: false,
    user: {
        id: null,
        role: null,
        country: 'SG',
        store_id: null
    },
    info: null
}

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setCountry: (state: AuthState, action: PayloadAction<string>) => {
            const user = {
                ...current(state).user,
                country: action.payload
            };
            localStorage.setItem('user', user ? JSON.stringify(user) : '');
            state.user = user;
        },
        setStore: (state: AuthState, action: PayloadAction<string>) => {
            const user = {
                ...current(state).user,
                store_id: action.payload
            }
            localStorage.setItem('user', user ? JSON.stringify(user) : '');
            state.user = user;
        },
        setAuth: (state: AuthState, action: PayloadAction<AuthState>) => {
            const result: AuthState = action.payload;
            state.accessToken = result.accessToken;
            state.accessKey = result.accessKey;
            state.authorization = result.authorization;
            state.authStatus = AuthStatusEnum.Logined;
            state.authVisible = true;
            state.user = result.user;
            state.info = result.info;
        },
        cleanAuth: (state: AuthState) => {
            state.accessToken = null;
            state.accessKey = null;
            state.authorization = null;
            state.authStatus = AuthStatusEnum.None;
            state.authVisible = false;
            state.user = null;
            state.info = null;
        }
    },
    extraReducers: (builder: ActionReducerMapBuilder<AuthState>) => {
        builder
            // register
            .addCase(registerAsync.fulfilled, (state: AuthState, action: PayloadAction<ResponseResult<RegisterResponse>>) => {
                state.user = {
                    id: action.payload.data.id
                }
            })
            // login 3 state
            .addCase(loginAsync.pending, (state: AuthState) => {
                state.authVisible = false;
                state.authStatus = AuthStatusEnum.Loading;
            })
            .addCase(loginAsync.fulfilled, (state: AuthState, action: PayloadAction<ResponseResult<LoginResponse>>) => {
                const { code, data } = action.payload;

                if (code === 200) {
                    // Set localStorage data
                    localStorage.setItem('MFAToken', data.mfatoken ? data.mfatoken : '');
                    // localStorage.setItem('AccessToken', data.token ? data.token : '');
                    // localStorage.setItem('AccessKey', data.token ? data.token : '');
                    // localStorage.setItem('Authorization', data.token ? data.token : '');
                    // localStorage.setItem('user', data.user ? JSON.stringify(data.user) : '');

                    // // Set state data
                    // state.accessToken = data.token;
                    // state.accessKey = data.token;
                    // state.authorization = data.token;
                    // state.authStatus = AuthStatusEnum.Logined;
                    // state.authVisible = true;
                    // state.user = data.user;
                } else {
                    state.accessToken = null;
                    state.accessKey = null;
                    state.authorization = null;
                    state.authVisible = false;
                    state.authStatus = AuthStatusEnum.LoginError;
                    state.user = null;
                    state.info = null;
                }
            })
            .addCase(loginAsync.rejected, (state: AuthState) => {
                state.accessToken = null;
                state.accessKey = null;
                state.authorization = null;
                state.authVisible = false;
                state.authStatus = AuthStatusEnum.LoginError;
                state.user = null;
                state.info = null;
            })
            // two fa state
            .addCase(validate2FATokenAsync.fulfilled, (state: AuthState, action: PayloadAction<ResponseResult<Validate2FATokenResponse>>) => {
                // Set localStorage data
                const { code, data } = action.payload;
                if (code === 200) {
                    localStorage.setItem('AccessToken', data.token ? data.token : '');
                    localStorage.setItem('AccessKey', data.token ? data.token : '');
                    localStorage.setItem('Authorization', data.token ? data.token : '');
                    localStorage.setItem('user', data.user ? JSON.stringify(data.user) : '');
                    localStorage.setItem('brands', JSON.stringify([]));


                    // Set state data
                    state.accessToken = data.token;
                    state.accessKey = data.token;
                    state.authorization = data.token;
                    state.authStatus = AuthStatusEnum.Logined;
                    state.authVisible = true;
                    state.user = data.user;
                } else {
                    state.accessToken = null;
                    state.accessKey = null;
                    state.authorization = null;
                    state.authVisible = false;
                    state.authStatus = AuthStatusEnum.LoginError;
                    state.user = null;
                    state.info = null;
                }
            })
            // get profile
            .addCase(getProfileAsync.fulfilled, (state: AuthState, action: PayloadAction<ResponseResult<GetProfileResponse>>) => {
                const { code, data } = action.payload;
                if (code === 200 && data.user[0]) {
                    // Set localStorage data
                    localStorage.setItem('info', JSON.stringify(data.user[0]));

                    // Set state data
                    state.info = data.user[0];
                } else {
                    state.info = null;
                }
            })
            .addCase(updateProfileAsync.fulfilled, (state: AuthState, action: PayloadAction<ResponseResult<Record<string, string | UpdateProfileParams>>>) => {
                const { code, data } = action.payload;
                if (code === 200) {
                    let cloneInfo = { ...state.info };
                    let cloneUser = { ...state.user };
                    const info = data['info'];
                    if (typeof (info) !== 'string') {
                        cloneInfo.mobile = info.mobile;
                        cloneInfo.store = info.store_id;
                        cloneUser.store_id = info.store_id;

                        localStorage.setItem('info', JSON.stringify(cloneInfo));
                        localStorage.setItem('user', JSON.stringify(cloneUser));
                        state.info = cloneInfo;
                        state.user = cloneUser;
                    }
                }
            })
            // login out 3 state
            .addCase(logoutAsync.pending, (state: AuthState, action) => {
                state.authVisible = false;
                state.authStatus = AuthStatusEnum.None;
            })
            .addCase(logoutAsync.fulfilled, (state: AuthState, action: PayloadAction<ResponseResult<string>>) => {
                const { code } = action.payload;
                if (code === 200) {
                    localStorage.removeItem('AccessToken');
                    localStorage.removeItem('AccessKey');
                    localStorage.removeItem('Authorization');
                    localStorage.removeItem('user');
                    localStorage.removeItem('info');
                    localStorage.removeItem('brands');

                    state.accessToken = null;
                    state.accessKey = null;
                    state.authorization = null;
                    state.authVisible = false;
                    state.authStatus = AuthStatusEnum.LoginError;
                    state.user = null;
                    state.info = null;
                }
            })
            .addCase(logoutAsync.rejected, (state: AuthState, action) => {
                state.authVisible = true;
                state.authStatus = AuthStatusEnum.Logined;
            })
    }
})

// Sync ation and selector
export const { setAuth, setCountry, setStore, cleanAuth } = authSlice.actions
export const selectAuth = (state: RootState) => state.auth

export default authSlice.reducer