import { useRef, useEffect } from 'react';
import { createPortal } from 'react-dom';

import { ReactComponent as LoadIcon } from '../assets/images/loading.svg';

import '../scss/base/loading.scss';

export type LazyLoadingProps = {
    loadQueues: LoadQueue[]
}

export type LoadQueue = {
    key: string,
    content?: string
}

export function Loading(props: LazyLoadingProps) {
    // Data
    const { loadQueues, ...otherProps } = props

    const el = useRef(document.createElement('div'));
    const current = el.current;

    useEffect(() => {
        current.className = `loading ${loadQueues.length > 0 ? 'loading-open' : ''}`;
        const modalRoot = document.querySelector(`#loading-root`) as HTMLElement;
        modalRoot!.appendChild(current);
        return () => void modalRoot!.removeChild(current);
    }, [loadQueues])

    const loadingNode = (
        <div className="loading-dialog" {...otherProps}>
            <div className="loading-content">
                <div><LoadIcon /></div>
                <div className="loading-text">LOADING...</div>
            </div>
        </div>
    )

    return createPortal(loadingNode, el.current)
}