const CONSTANTS = {
    API: {
        PREFIX: {
            LOCAL: {
                HOST: 'localhost',
                PORT: 3001,
            },
            DEVOLD: {
                PROTOCOL: 'https',
                HOST: 'lux-ceapp-api.uat.ecddigital.com.au',
                PORT: 443,
            },
            DEV: {
                PROTOCOL: 'https',
                HOST: 'lux-ceapp-api.uat.ecddigital.com.au',
                PORT: 443,
            },
        },
        MOCK: false,
    },
    ROUTES: {
        HOME: '/home',
        NOFOUND: '*',
        LOGIN: '/login',
        TWO_FA_LOGIN: '/two-fa-login',
        REGISTER: '/register',
        VALIDATE_EMPLOYEE_ID: '/validate-employee-id',
        VALIDATE_EMPLOYEE_EMAIL: '/validate-employee-email',
        SECRET_QUESTION: '/secret-question',
        PASSWORD_REST: '/password-reset',
        PASSWORD_REST_BY_TOKEN: '/password-reset-by-token',
        PASSWORD_SET: '/password-set',
        SELECT_LANGUAGE: '/select-language',
        SELECT_STORE: '/select-store',
        SELECT_BRANDS: '/select-brands',
        CUSTOMER_SEARCH: '/customer-search',
        CUSTOMER_PROFILE: '/customer-profile',
        NEW_CUSTOMER_PROFILE: '/new-customer-profile',
        NEW_CUSTOMER_LIST: '/new-customer-list',
        TRANSACTION: '/transaction',
        TRANSACTIONS: '/transactions',
        CONTACT_LIST: '/contact-list',
        CONTACT_LIST_NOTE: '/contact-list-note',
        PRODUCT_SEARCH: '/product-search',
        MY_PROFILE: '/profile',
        CAMPAIGNS: '/campaigns',
        SUPPORT: '/support',
        MULTI_BRAND_CONSENT: '/multi-brand-consent'
    },
    DEFAULT_LANGUAGE: 'SG'
}

export default CONSTANTS
