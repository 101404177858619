import { ActionReducerMapBuilder, createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../store';

import { getLanguageFile, GetLanguageFileParams, GetLanguageFileRespone } from '../apis/commonRequest';
import { ResponseResult } from '../../utils/httpRequest';

// --- Types --
// Data
export interface I18nState {
    [key: string]: unknown
}

// --- Functions ---
// Async functions
export const getLanguageFileAsync = createAsyncThunk('getLanguageFileAsync', async (params: GetLanguageFileParams) => {
    const response: ResponseResult<GetLanguageFileRespone> = await getLanguageFile(params);
    return response;
})

// Reducer
const initialState: I18nState = {
}

export const i18nSlice = createSlice({
    name: 'i18n',
    initialState,
    reducers: {},
    extraReducers: (builder: ActionReducerMapBuilder<I18nState>) => {
        builder
            // Get language file
            .addCase(getLanguageFileAsync.fulfilled, (state: I18nState, action: PayloadAction<ResponseResult<GetLanguageFileRespone>>) => {
                const result = action.payload;
                if (result.code === 200 || result.code === 304) {
                    state[result.data.key] = result.data.value
                }
            })
    }
})

// Sync ation and selector
export const selectI18n = (state: RootState) => state.i18n

export default i18nSlice.reducer