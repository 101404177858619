import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../store';

// --- Types --
// Data
export interface BrandState {
    brands: Brand[]
}

export interface Brand {
    id: string,
    description: string
    consent_toggle?: boolean
}

// --- Functions ---
// Async functions

// Reducer
const initialState: BrandState = {
    brands: []
}

export const brandSlice = createSlice({
    name: 'brand',
    initialState,
    reducers: {
        setBrands: (state: BrandState, action: PayloadAction<Brand[]>) => {
            localStorage.setItem('brands', JSON.stringify(action.payload));
            state.brands = action.payload;
        },
        cleanBrands: (state: BrandState) => {
            localStorage.removeItem('brands');
            state.brands = [];
        }
    }
})

// Sync ation and selector
export const { setBrands, cleanBrands } = brandSlice.actions
export const selectBrand = (state: RootState) => state.brand

export default brandSlice.reducer