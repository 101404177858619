import axios, { AxiosError, AxiosRequestConfig, AxiosRequestHeaders, AxiosResponse } from 'axios';
// import CONSTANTS from '../constants/globalConfig';

/**
 * Define http request types
 */
export type ResponseResult<T = any> = {
    code: number,
    data: T,
    msg: string
}

// Axios instance
const httpRequest = axios.create()
// Set proxy and baseUrl
console.log(process.env)
httpRequest.defaults.baseURL = `${process.env.REACT_APP_API_BASE_URL}`;


// if (process.env.NODE_ENV === 'development') {
//     httpRequest.defaults.baseURL = `${CONSTANTS.API.PREFIX.DEV.PROTOCOL}://${CONSTANTS.API.PREFIX.DEV.HOST}`;
//     // httpRequest.defaults.baseURL = `http://localhost:3001`;
// }
// if (process.env.NODE_ENV === 'production') {

// }


// Interceptors applies to every ajax call
httpRequest.interceptors.request.use(
    (config: AxiosRequestConfig<any>) => {
        // Auth handle
        let headers: AxiosRequestHeaders = {
            "AccessToken": `${localStorage.getItem('AccessToken')}`,
            "AccessKey": `${localStorage.getItem('AccessKey')}`,
            "Authorization": `${localStorage.getItem('Authorization')}`
        }
        config.headers = headers;

        //  Request info log
        console.log('========================== Request ==========================');
        console.log('Method: ', config.method);
        console.log('URL: ', config.url);
        console.log('========================== Response ==========================');
        return config
    },
    (err: Error | AxiosError) => {
        return Promise.reject(err)
    }
)

// Global response result config
httpRequest.interceptors.response.use(
    (res: AxiosResponse<ResponseResult, any>) => {
        const result: ResponseResult = {
            code: res.status,
            msg: 'request success',
            data: res.data
        }
        return result;
    },
    (err: Error | AxiosError) => {
        if (axios.isAxiosError(err)) {
            // Access to config, request, and response
            const code: number = err.response?.status && Number(err.response?.status) > 0 ? Number(err.response?.status) : -1
            if (code === 401 || code === 403) {
                localStorage.removeItem('AccessToken');
                localStorage.removeItem('AccessKey');
                localStorage.removeItem('Authorization');
                localStorage.removeItem('user');
                localStorage.removeItem('info');
            }

            const result: ResponseResult = {
                code: code,
                msg: `${err.response?.data ? err.response?.data : 'request api error'}`,
                data: null
            }
            return Promise.reject(result);
        } else {
            // Just a stock error
            const result: ResponseResult = {
                code: -1,
                msg: 'request error',
                data: null
            }
            return Promise.reject(result);
        }
    })

export default httpRequest