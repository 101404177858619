import { AxiosRequestConfig } from 'axios';
import httpRequest, { ResponseResult } from '../../utils/httpRequest';

// -- Types --
// Method parameter
export interface GetLanguageFileParams {
    page: string,
    language: string
}
export interface GetLanguageFileRespone {
    key: string,
    value: object
}

// -- Methods --
export async function getLanguageFile(params: GetLanguageFileParams): Promise<ResponseResult<GetLanguageFileRespone>> {
    let language = 'sg';
    switch (params.language.toLocaleUpperCase()) {
        case "CHINA":
        case "CH":
        case "CN":
            language = "china";
            break;
        case "VIETNAM":
        case "VN":
            language = "vietnam";
            break;
        case "THAILAND":
        case "TH":
            language = "thailand";
            break;
        case "INDONESIA":
        case "ID":
            language = "indonesia";
            break;
        case "MALAYSIA":
        case "MY":
        case "SINGAPORE":
        case "SG":
        case "PHILIPPINES":
        case "PH":
        case "AUSTRALIA":
        case "AU":
        case "INDIA":
        case "IN":
        case "SRI LANKA":
        case "LK":
        case "CAMBODIA":
        case "KH":
        case "NEW ZEALAND":
        case "NZ":
            language = "sg";
            break;
        case "TAIWAN":
        case "TW":
        case "HONG KONG":
        case "HK":
            language = "tw";
            break;
        default:
            language = "sg";
            break;
    }

    const url = `/data/${language}/${params.page}.js`;
    // Change to local base url
    const config: AxiosRequestConfig = {
        baseURL: ''
    }
    return await httpRequest.get<ResponseResult<GetLanguageFileRespone>>(url, config).then((res: any) => {
        const result: ResponseResult<GetLanguageFileRespone> = res;
        result.data = {
            key: params.page,
            value: res.data
        }
        return result;
    }).catch((err) => err)
}
