import { useEffect, useRef, ComponentProps } from 'react'
import { createPortal } from 'react-dom'
import { selectBase } from '../app/slices/baseSlices'
import { useAppSelector } from '../app/hooks'

export enum MessageEnum {
	NONE,
	ERROR,
	WARN,
	INFO,
	SUCCESS,
}

export type MessageProps = ComponentProps<'div'> & {
	rootId: string
	visible: boolean
	state?: MessageEnum
	children?: React.ReactNode | string
	showTime?: number
	closeMessage?: () => void
}

export function Message(props: MessageProps) {
	// Data
	const { rootId, visible, state, children, showTime, closeMessage, ...otherProps } = props

	const el = useRef(document.createElement('div'))
	const current = el.current

	const base = useAppSelector(selectBase)

	useEffect(() => {
		if (children) {
			let timeout = base?.message?.content && base.message?.content?.split(' ').length > 3 ? 5000 : 3000
			const timerId = setInterval(() => {
				closeMessage && closeMessage()
			}, timeout)

			return () => {
				clearInterval(timerId)
			}
		}
	}, [children, base.message?.content, closeMessage])

	useEffect(() => {
		current.className = `message ${visible ? 'message-open' : ''}`
		// We assume `modalRoot` exists with '!'
		const modalRoot = document.querySelector(`#${rootId}`) as HTMLElement
		modalRoot!.appendChild(current)
		return () => void modalRoot!.removeChild(current)
	}, [current, visible, rootId])

	const messageNode = (
		<div className="message-dialog" {...otherProps}>
			<div className="message-content">
				<span>{children}</span>
				<button type="button" onClick={closeMessage}>
					Close
				</button>
			</div>
		</div>
	)
	return createPortal(messageNode, el.current)
}
